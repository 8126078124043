import { UseGetCustomerProps } from './useCustomer.types'
import { getCustomer } from '../../../services/api/customer/CustomerService'
import { makePaginationApiQueryBuilder } from '../../../utils/ApiHelper'
import { useCurrentUser } from '../../../auth/auth'
import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import useAxios from '../../useAxios'

const FILTER_MAPPING: Record<string, string> = {}

const SORT_MAPPING: Record<string, string> = {}

export const useGetCustomer = ({
    filters = [],
    sorts = [],
    pagination = { pageIndex: 0, pageSize: 15 },
    onUpdateQueryParams,
    onSuccess
}: UseGetCustomerProps) => {
    const { isReady, isLogged } = useCurrentUser()
    const axiosInstance = useAxios()

    const apiParams = useMemo<string>(() => {
        return makePaginationApiQueryBuilder({
            filters,
            sorts,
            pagination,
            sortMapping: SORT_MAPPING,
            filterMapping: FILTER_MAPPING
        }).get()
    }, [filters, pagination, sorts])

    return useQuery(
        ['customer', apiParams],
        async ({ signal }) => {
            return getCustomer(axiosInstance, apiParams, { signal })
        },
        {
            keepPreviousData: true,
            enabled: isReady && isLogged,
            onSuccess,
            onSettled: data => {
                if (data?.meta?.currentPage === pagination.pageIndex + 1 && data?.data?.length) {
                    return
                }
                onUpdateQueryParams?.({
                    filters,
                    sorts,
                    pagination: {
                        pageIndex: 0,
                        pageSize: pagination.pageSize
                    }
                })
            }
        }
    )
}
