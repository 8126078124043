import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { LoginAsRequest } from './loginAsService.types'
import { MakeAxiosResponse } from '../../Service.types'
import { MakeUserWithAccessToken } from '@dactyl-npm-packages/dactyl-auth-react'
import { User } from '../../../auth/auth'

export const loginAs = async (
    axiosInstance: AxiosInstance,
    request: LoginAsRequest,
    config: AxiosRequestConfig = {}
): Promise<MakeUserWithAccessToken<User>> => {
    const {
        data: { data }
    } = await axiosInstance.post<MakeAxiosResponse<MakeUserWithAccessToken<User>>>('/login-as', request, config)
    return data
}
