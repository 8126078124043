import { AccessToken } from '@dactyl-npm-packages/dactyl-auth-react'
import { ColumnFilter } from '@tanstack/react-table'
import { Customer } from '../../services/api/customer/CustomerService.types'
import { useCallback, useState } from 'react'
import { useCurrentUser } from '../../auth/auth'
import { useGetCustomer } from '../../hooks/api/customer/useGetCustomer'
import { useLoginAs } from '../../hooks/api/login-as/useLoginAs'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import Button from '../base/button/Button'
import SelectFieldPaginate from '../base/select-field-paginate/SelectFieldPaginate'
import useToast from '../../hooks/useToast'

export const LoginFormAdminCustomerSelector = () => {
    const { t } = useTranslation()
    const router = useRouter()
    const toast = useToast()
    const [filters, setFilters] = useState<ColumnFilter[]>([])
    const [page, setPage] = useState(0)
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null)
    const {
        data: { data: customers = [] } = {},
        refetch,
        isLoading
    } = useGetCustomer({
        filters,
        sorts: [],
        pagination: { pageIndex: page, pageSize: 15 }
    })
    const { mutate: loginAs } = useLoginAs()
    const { setAccessToken } = useCurrentUser()

    const onInputChange = useCallback((inputValue: string) => {
        setFilters([{ id: 'fullName', value: { type: 'string', value: inputValue } }])
        setPage(0)
    }, [])

    const loadOptions = async (inputValue: string, loadedOptions: Customer[], { page }: { page: number }) => {
        setFilters([{ id: 'fullName', value: { type: 'string', value: inputValue } }])
        setPage(page)
        const newCustomers = await refetch()
        const hasMore = newCustomers?.data?.meta?.currentPage < newCustomers?.data?.meta?.lastPage
        return {
            options: newCustomers?.data?.data || [],
            hasMore,
            additional: {
                page: page + 1
            }
        }
    }

    const handleChange = (option: Customer | null) => {
        setSelectedCustomer(option)
    }

    const onContinueWithChosenCustomer = () => {
        if (!selectedCustomer) {
            return toast.error(t('frontend.admin.choose_customer_error'))
        }
        loginAs(
            { userId: selectedCustomer.id },
            {
                onSuccess: data => {
                    const token: AccessToken = data.token
                    setAccessToken(token)
                    router.push('/app')
                }
            }
        )
    }

    return (
        <div>
            <SelectFieldPaginate
                label={t('frontend.admin.choose_customer')}
                isSearchable
                isLoading={isLoading}
                options={customers}
                value={selectedCustomer}
                getOptionLabel={(option: Customer) => option.fullName}
                getOptionValue={(option: Customer) => option.id.toString()}
                onChange={handleChange}
                onInputChange={onInputChange}
                loadOptions={loadOptions}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
            />
            <Button className='mt-6' onClick={onContinueWithChosenCustomer}>
                {t('frontend.admin.choose_customer_continue')}
            </Button>
        </div>
    )
}
