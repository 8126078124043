import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { Customer } from './CustomerService.types'
import { MakeAxiosPaginatedResponse } from '../../Service.types'

export const getCustomer = async (
    axiosInstance: AxiosInstance,
    query: string,
    config: AxiosRequestConfig = {}
): Promise<MakeAxiosPaginatedResponse<Customer>> => {
    const { data } = await axiosInstance.get<MakeAxiosPaginatedResponse<Customer>>(`/customer${query}`, config)
    return data
}
