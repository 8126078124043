import { AxiosError } from 'axios'
import { LoginAsRequest } from '../../../services/api/login-as/loginAsService.types'
import { MakeUserWithAccessToken } from '@dactyl-npm-packages/dactyl-auth-react'
import { User } from '../../../auth/auth'
import { loginAs } from '../../../services/api/login-as/loginAsService'
import { useMutation } from '@tanstack/react-query'
import useAxios from '../../useAxios'

export const useLoginAs = () => {
    const axiosInstance = useAxios()

    return useMutation<MakeUserWithAccessToken<User>, AxiosError, LoginAsRequest>(['loginAs'], request => {
        return loginAs(axiosInstance, request)
    })
}
