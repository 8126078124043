import ApiQueryBuilder from './ApiQueryBuilder'
import qs from 'qs'

class ApiQueryParser {
    queryBuilder: ApiQueryBuilder
    uri: string

    constructor(query) {
        this.queryBuilder = query
        this.uri = ''
    }

    parse() {
        this.filters()
        this.sorts()
        this.page()
        this.limit()
        this.params()

        return this.uri
    }

    prepend() {
        return this.uri === '' ? '?' : '&'
    }

    filters() {
        if (!Object.keys(this.queryBuilder.filters).length) {
            return
        }
        const filters = { [this.queryBuilder.paramNames.filters]: this.queryBuilder.filters }
        this.uri += this.prepend() + qs.stringify(filters, { encode: false })
    }

    sorts() {
        if (!this.queryBuilder.sorts.length) {
            return
        }
        this.uri += `${this.prepend() + this.queryBuilder.paramNames.sort}=${this.queryBuilder.sorts}`
    }

    page() {
        if (!this.queryBuilder.pageValue) {
            return
        }
        this.uri += `${this.prepend() + this.queryBuilder.paramNames.page}=${this.queryBuilder.pageValue}`
    }

    limit() {
        if (!this.queryBuilder.perPageValue) {
            return
        }
        this.uri += `${this.prepend() + this.queryBuilder.paramNames.perPage}=${this.queryBuilder.perPageValue}`
    }

    params() {
        if (!this.queryBuilder.additionalParams) {
            return
        }
        this.uri += this.prepend() + qs.stringify(this.queryBuilder.additionalParams, { encode: false })
    }
}

export default ApiQueryParser
